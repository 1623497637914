const form = document.querySelector('.form-filter');

const setFormMargin = () => {
    console.log("form");
    console.log(form);
	if ( form ) {
        console.log(form.clientHeight);
		form.style.setProperty('--margin', `-${form.clientHeight / 2}px`);
		form.previousElementSibling.style.setProperty('--margin', `${form.clientHeight / 2}px`);
	}
};

document.addEventListener('DOMContentLoaded', setFormMargin);
window.addEventListener('load', setFormMargin);
window.addEventListener('resize', setFormMargin);
